<app-global-style></app-global-style>
<div [ngClass]="{'login-overlay-white': showLoginOverlay}">
  <div class="login-overlay floating-image animated fadeIn" #loginOverlay>
    <div class="login-locale-select" *ngIf="hasLocaleSupport">
      <app-locale-select></app-locale-select>
    </div>
    <div class="container py-5">
      <div class="row">
        <div class="col-md-12">
          <h2 class="text-center text-white mb-4"><br><br></h2>
          <div class="row">
            <div class="col-md-4 mx-auto">
              <span class="anchor" id="formLogin"></span>

              <!-- form card login -->
              <div class="card rounded-2 animated flipInX" #loginCard *ngIf="!showForgotPasswordCard && !showTosCard">
                <div class="card-header">
                  <h3 class="mb-0" *ngIf="!!tenantName">{{tenantName}}</h3>
                  <h3 class="mb-0" *ngIf="!tenantName">{{_(KEYS.LOGIN.LOGIN)}}</h3>
                </div>
                <div class="card-body">
                  <form class="form" role="form" autocomplete="off" [formGroup]="loginForm" (ngSubmit)="loginForm.valid && !loading && onSubmit()">
                    <div class="form-group">
                      <label for="username">{{_(KEYS.LOGIN.USERNAME)}}</label>

                      <div class="input-group mb-2 mb-sm-0">
                        <div class="input-group-addon">
                          <i class="fa fa-user"></i>
                        </div>
                        <input
                          type="text"
                          class="form-control form-control-lg rounded-2"
                          name="username"
                          id="username"
                          formControlName="username">
                      </div>

                      <div
                        class="invalid-feedback display"
                        *ngIf="(!username.valid) && (username.dirty || username.touched)">
                        {{_(KEYS.LOGIN.ENTER_VALID_USERNAME)}}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="password">{{_(KEYS.LOGIN.PASSWORD)}}</label>

                      <div class="input-group mb-2 mb-sm-0">
                        <div class="input-group-addon">
                          <i class="fa fa-key"></i>
                        </div>
                        <input
                          type="password"
                          class="form-control form-control-lg rounded-2"
                          name="password"
                          id="password"
                          formControlName="password">
                      </div>

                      <div
                        class="invalid-feedback display"
                        *ngIf="(!password.valid) && (password.dirty || password.touched)">
                        {{_(KEYS.LOGIN.ENTER_VALID_PASSWORD)}}
                      </div>
                    </div>

                    <!-- 'stay logged in' checkbox and 'forgot password?' button in a flex container -->
                    <div class="d-flex justify-content-between">
                      <div class="form-check">
                        <label class="form-check-label">
                          <input type="checkbox" class="form-check-input" id="remember" name="remember" formControlName="remember">
                          {{_(KEYS.LOGIN.STAY_LOGGED_IN)}}
                        </label>
                      </div>
                      <button
                        type="button"
                        class="btn btn-link p-0 forgot-pw-btn"
                        style="text-decoration: underline;"
                        (click)="onShowForgotPwForm()"
                        *ngIf="canUsePasswordReset"
                      >
                        {{_(KEYS.LOGIN.FORGOT_PASSWORD_HINT)}}
                      </button>
                    </div>

                    <div *ngIf="!!errorMsg" class="remote-error">
                      <div class="alert alert-danger">
                        {{errorMsg}}
                      </div>
                    </div>
                    <br/>
                    <button
                      type="submit"
                      class="btn btn-success btn-lg float-right"
                      [disabled]="!loginForm.valid || loading" (click)="onSubmit()">
                      {{_(KEYS.LOGIN.LOGIN)}}
                    </button>
                  </form>
                </div>
              </div>

              <!-- form card forgot password -->
              <div class="row" *ngIf="showForgotPasswordCard">
                <div class="col-md-4">
                  <div class="card rounded-2 animated fadeIn" #forgotPwCard>
                    <div class="card-header">
                      <h3 class="mb-0">{{_(KEYS.LOGIN.FORGOT_PASSWORD)}}</h3>
                    </div>
                    <div class="card-body">
                      <form class="form" role="form" autocomplete="off" [formGroup]="forgotPwForm" (ngSubmit)="forgotPwForm.valid && !loading && onSubmitForgotPw">
                        <!-- Username Field -->
                        <div class="form-group">
                          <label for="usernameForgot">{{_(KEYS.LOGIN.USERNAME)}}</label>
                          <div class="input-group mb-2 mb-sm-0">
                            <div class="input-group-addon">
                              <i class="fa fa-user"></i>
                            </div>
                            <input type="text" class="form-control form-control-lg rounded-2" id="usernameForgot" formControlName="usernameForgot">
                          </div>

                          <div
                            class="invalid-feedback display"
                            *ngIf="(!usernameForgot.valid) && (usernameForgot.dirty || usernameForgot.touched)">
                            {{_(KEYS.LOGIN.ENTER_VALID_USERNAME)}}
                          </div>
                        </div>
                        <!-- Captcha Field -->
                        <div class="form-group">
                          <label for="captcha">{{_(KEYS.LOGIN.CAPTCHA)}}</label>
                          <div class="input-group mb-2 mb-sm-0">
                            <div class="input-group-addon">
                              <i class="fa fa-puzzle-piece"></i>
                            </div>
                            <div class="input-group-addon captcha-img-container">
                              <img [src]="captchaImageUrl" alt="{{_(KEYS.LOGIN.CAPTCHA_ALT)}}" class="captcha-img">
                            </div>
                            <input type="text" class="form-control form-control-lg rounded-2" id="captcha" formControlName="captcha" placeholder="...">
                          </div>

                          <div
                            class="invalid-feedback display"
                            *ngIf="(!captcha.valid) && (captcha.dirty || captcha.touched)">
                            {{_(KEYS.LOGIN.ENTER_VALID_CAPTCHA)}}
                          </div>
                        </div>
                        <button type="button" class="btn btn-link p-0" style="text-decoration: underline;" (click)="showForgotPasswordCard = false">
                          {{_(KEYS.LOGIN.BACK_TO_LOGIN)}}
                        </button>

                        <div *ngIf="!!errorMsg" class="remote-error">
                          <div class="alert alert-danger">
                            {{errorMsg}}
                          </div>
                        </div>
                        <br/>
                        <button
                          type="submit"
                          class="btn btn-success btn-lg float-right"
                          [disabled]="!forgotPwForm.valid || loading" (click)="onSubmitForgotPw()">
                          {{_(KEYS.LOGIN.FORGOT_PASSWORD_SUBMIT)}}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="showTosCard">
                <div class="col-md-4">
                  <div class="card rounded-2 animated fadeIn" #tosCard>
                    <div class="card-header">
                      <h3 class="mb-0">
                        {{_(KEYS.LOGIN.TOS_HEADLINE)}}
                      </h3>
                    </div>
                    <div class="card-body">
                      <div class="tos">
                        <p>  Bitte lesen Sie die folgenden, für jeden Besucher der Website geltenden Nutzungsbedingungen durch. Durch Nutzung dieser Website erklären Sie konkludent Ihr Einverständnis mit diesen Nutzungsbedingungen. Der Inhaber der Website behält sich das Recht vor, die Informationen auf dieser Website jederzeit ohne vorherige Ankündigung zu ändern, zu streichen oder zu ergänzen.</p>
                        <p>  <strong>§ 1</strong></p>
                        <p>  <strong>Geltungsbereich</strong></p>
                        <ol>
                          <li><p>Die nachfolgenden Bedingungen gelten für die Nutzung der Website myMedax (nachfolgend "Website"). Für die Nutzung der Website ist wichtig, dass Sie als Nutzer/in die nachfolgenden Bestimmungen akzeptieren.</p>
                          </li>
                          <li><p>Durch die Nutzung unserer Website sind Sie mit den Nutzungsbedingungen unserer Website einverstanden. Durch Ihr Einverständnis garantieren Sie uns, dass Sie keine Beiträge erstellen werden, die gegen die Nutzungsbedingung verstoßen.</p>
                          </li>
                          <li><p>Gegenstand von myMedax ist Folgendes:</p>
                          </li>
                        </ol>
                        <p>  Bereitstellung eines Fragebogensystems zur Erhebung von Daten über ein online ausfüllbares Formular.</p>
                        <p>  <strong>§ 2</strong></p>
                        <p>  <strong>Kontaktdaten und rechtliche Hinweise</strong></p>
                        <p>  Sollten Sie Fragen bezüglich unserer Website haben, können Sie uns unter folgenden Kontaktdaten erreichen:</p>
                        <p>  SUXEDO GmbH &amp; Co. KG</p>
                        <p>  Sulzbachtalstr. 128	&nbsp;
                          66125 Saarbrücken</p>
                        <p>  Telefon: +49 6897 9392910</p>
                        <p>  E-Mail: <a href="mailto:support@suxedo.de">support@suxedo.de</a></p>
                        <p>  Geschäftsführer: Dirk Becker, Franz-Werner Britz</p>
                        <p>  Registergericht: Saarbrücken</p>
                        <p>  Registernummer: HRA 11981</p>
                        <p>  <strong>§ 3</strong></p>
                        <p>  <strong>Verfügbarkeit der Website</strong></p>
                        <ol>
                          <li><p>myMedax hat eine Verfügbarkeit von 24 Stunden am Tag. Es kann jedoch vorkommen, dass es aufgrund von Wartungen, die für das Systems erforderlich sind, zu Unterbrechungen der Verfügbarkeit kommt. Unterbrechungen der Verfügbarkeit können unter Anderem aufgrund höherer Gewalt oder anderen, von myMedax nicht zu vertretenden Ursachen, wie zum Beispiel Vorsatz oder grobe Fahrlässigkeit vorkommen.</p>
                          </li>
                          <li><p>SUXEDO GmbH &amp; Co. KG weist darauf hin:</p>
                          </li>
                        </ol>
                        <ul>
                          <li>dass es technisch unmöglich ist, die Webseite frei von Fehlern jedweder Art zur Verfügung zu stellen und dass SUXEDO GmbH &amp; Co. KG deshalb keinerlei Verantwortung dafür übernimmt,</li>
                          <li>dass Fehler zur zeitweisen Abschaltung der Website führen können,</li>
                          <li>dass die Verfügbarkeit dieser Webseiten von außerhalb des Einflussbereichs von SUXEDO GmbH &amp; Co. KG liegenden Bedingungen und Leistungen abhängig ist, wie z.B. den Übertragungskapazitäten und Telefonverbindungen zwischen den einzelnen Beteiligten. In diesen Bereich fallende Störungen haben wir nicht zu verantworten.</li>
                        </ul>
                        <p>  <strong>§ 4</strong></p>
                        <p>  <strong>Diskussionsforen</strong></p>
                        <p>  Soweit SUXEDO GmbH &amp; Co. KG zeitweise oder dauerhaft auf ihren Webseiten Diskussionsforen anbietet, wird von den Teilnehmern der Diskussionsforen erwartet, dass sie die üblichen Kommunikationsregeln wie gegenseitigen Respekt beachten. Besucher dürfen keine beleidigenden, eventuell herabwürdigenden, unflätigen, anstößigen, diffamierenden oder obszönen Materialien oder Materialien, die die geistigen Eigentumsrechte Dritter verletzen, verbreiten oder veröffentlichen.</p>
                        <p>  <strong>§ 5</strong></p>
                        <p>  <strong>Elektronische Kommunikation</strong></p>
                        <p>  Wenn Sie einen Dienst von myMedax nutzen oder E-Mails, Textnachrichten oder andere Mitteilungen von Ihrem Computer oder Ihrem mobilen Gerät an uns senden, kommunizieren Sie mit uns elektronisch. Wir werden mit Ihnen auf verschiedene Art und Weise elektronisch kommunizieren, z.B. über E-Mail, Textnachrichten oder auch durch die Veröffentlichung elektronischer Nachrichten oder sonstiger Kommunikation auf unserer Website oder im Rahmen sonstiger Dienste von myMedax. Für vertragliche Zwecke stimmen Sie zu, elektronische Kommunikation von uns zu erhalten und dass alle Zustimmungen, Benachrichtigungen, Veröffentlichungen und andere Kommunikation, die wir Ihnen elektronisch mitteilen insofern keine Schriftform erfordern, es sei denn zwingend anzuwendende gesetzliche Vorschriften erfordern eine andere Form der Kommunikation.</p>
                        <p>  <strong>§ 6</strong></p>
                        <p>  <strong>Urheberrecht und Datenbankrechte</strong></p>
                        <ol>
                          <li><p>Der gesamte Inhalt, der in einem Dienst von myMedax enthalten oder durch ihn bereitgestellt wird, wie Text, Grafik, Logos, Button-Icons, Bilder, Audio-Clips, digitale Downloads und Datensammlungen, ist Eigentum von myMedax oder von Dritten, die Inhalte zuliefern oder auf der Website bereitstellen und ist durch deutsches Urheberrecht und Datenbankrecht geschützt.</p>
                          </li>
                          <li><p>Auch der Gesamtbestand der Inhalte, der in einem Dienst von myMedax enthalten oder durch ihn bereitgestellt wird, ist ausschließliches Eigentum von myMedax und ist durch deutsches Urheberrecht und Datenbankrecht geschützt.</p>
                          </li>
                          <li><p>Sie dürfen ohne unsere ausdrückliche schriftliche Zustimmung nicht Teile eines Dienstes von myMedax systematisch extrahieren und/oder wiederverwenden. Insbesondere dürfen Sie ohne die ausdrückliche schriftliche Zustimmung kein Data Mining, keine Robots oder ähnliche Datensammel- und Extraktionsprogramme einsetzen, um irgendwelche wesentlichen Teile eines Dienstes von myMedax zur Wiederverwendung zu extrahieren (gleichgültig ob einmalig oder mehrfach). Sie dürfen ferner ohne die ausdrückliche schriftliche Zustimmung keine eigene Datenbank herstellen und/oder veröffentlichen, die wesentliche Teile eines Dienstes von myMedax beinhaltet.</p>
                          </li>
                        </ol>
                        <p>  <strong>§ 7</strong></p>
                        <p>  <strong>Patente</strong></p>
                        <p>  Alle Patente die auf myMedax angemeldet sind, sind auch auf alle Dienste und sonstige Produkte von myMedax anwendbar. Es kann vorkommen, dass die jeweiligen Patente unter einem oder mehreren Patenten betrieben werden.</p>
                        <p>  <strong>§ 8</strong></p>
                        <p>  <strong>Ihr Konto</strong></p>
                        <ol>
                          <li><p>Der Zugang zu einigen Diensten der Website, setzt die Erstellung eines Kontos voraus.</p>
                          </li>
                          <li><p>Die Erstellung eines Kontos ist nur voll geschäftsfähigen Personen gestattet, denen das Anmeldeformular auf der Website bereit gestellt wird.</p>
                          </li>
                          <li><p>Der Nutzer verpflichtet sich dazu, keine falschen Angaben zu seiner Person zu machen. Des Weiteren verpflichtet sich der Nutzer dazu, seine Angaben regelmäßig zu kontrollieren, um die Richtigkeit dieser zu gewährleisten.</p>
                          </li>
                          <li><p>Wenn Sie einen Dienst von myMedax nutzen, sind Sie für die Sicherstellung der Vertraulichkeit Ihres Kontos, Passworts und für die Beschränkung des Zugangs zu Ihrem Computer und Ihren mobilen Geräten verantwortlich. Soweit unter anwendbarem Recht zulässig, erklären Sie sich damit einverstanden, für alle Aktivitäten verantwortlich zu sein, die über Ihr Konto oder Passwort vorgenommen werden. Sie sollten alle erforderlichen Schritte unternehmen, um sicherzustellen, dass Ihr Passwort geheim gehalten und sicher aufbewahrt wird und Sie sollten uns unverzüglich informieren, wenn Sie Anlass zur Sorge haben, dass ein Dritter Kenntnis von Ihrem Passwort erlangt hat oder das Passwort unautorisiert genutzt wird oder dies wahrscheinlich ist. Sie sind dafür verantwortlich, sicherzustellen, dass Ihre Angaben an uns korrekt und vollständig sind und dass Sie uns von jeglichen Änderungen hinsichtlich der von Ihnen gegebenen Informationen in Kenntnis setzen. Sie können einige der Informationen, die Sie uns gegeben haben, auf unserer Website einsehen und aktualisieren.</p>
                          </li>
                          <li><p>Der Nutzer kann seinen Benutzernamen und sein Passwort nachträglich jederzeit ändern.</p>
                          </li>
                          <li><p>Sie dürfen einen Dienst von myMedax nicht in einer Weise verwenden, die dazu geeignet ist, die Dienste oder den Zugang von myMedax zu unterbrechen, zu beschädigen oder in sonstiger Art zu beeinträchtigen.</p>
                          </li>
                          <li><p>Des Weiteren dürfen Sie die Dienste von myMedax nicht für betrügerische oder in Verbindung mit einer Straftat, rechtswidrigen Aktivitäten, Belästigungen oder Unannehmlichkeiten verwenden.</p>
                          </li>
                          <li><p>Wir behalten uns das Recht vor, Ihnen die Dienste auf der Website vorzuenthalten oder Mitgliedskonten zu schließen. Das gilt insbesondere für den Fall, dass Sie gegen anwendbares Recht, vertragliche Vereinbarungen oder unsere Richtlinien verstoßen.</p>
                          </li>
                          <li><p>Die Nutzer dieser Website können ihr Konto jederzeit löschen, indem Sie eine entsprechende Nachricht über das Kontaktformular abschicken. Das Konto des Nutzers wird unverzüglich gelöscht, sobald der Nutzer das Formular ausgefüllt und abgeschickt hat.</p>
                          </li>
                        </ol>
                        <p>  <strong>§ 9</strong></p>
                        <p>  <strong>Ansprüche aus Immaterialgüterrechten</strong></p>
                        <p>  myMedax respektiert die Immaterialgüterrechte Dritter. Wenn Sie der Auffassung sind, dass Ihre Immaterialgüterrechte in einer Art genutzt wurden, der Anlass zur Befürchtung einer Verletzung gibt, folgen Sie bitte unserem Verfahren zur Mitteilung über eine Rechtsverletzung.</p>
                        <p>  <strong>§ 10</strong></p>
                        <p>  <strong>Haftung</strong></p>
                        <ol>
                          <li><p>Wir bemühen uns stets sicherzustellen, dass die Dienste von myMedax ohne Unterbrechungen verfügbar und Übermittlungen fehlerfrei sind. Durch die Beschaffenheit des Internets kann dies jedoch nicht garantiert werden. Auch Ihr Zugriff auf die Dienste von myMedax kann gelegentlich unterbrochen oder beschränkt sein, um Instandsetzungen, Wartungen oder die Einführung neuer Einrichtungen zu ermöglichen. Wir versuchen die Häufigkeit und Dauer jeder dieser vorübergehenden Unterbrechungen oder Beschränkungen zu begrenzen.</p>
                          </li>
                          <li><p>myMedax haftet unbeschränkt, soweit die Schadensursache auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung von myMedax oder eines gesetzlichen Vertreters oder Erfüllungsgehilfen von myMedax beruht.</p>
                          </li>
                          <li><p>Ferner haftet myMedax für die leicht fahrlässige Verletzung von wesentlichen Pflichten. Wesentlich sind Pflichten, deren Verletzung die Erreichung des Vertragszwecks gefährdet oder deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung Sie regelmäßig vertrauen. In diesem Fall haftet myMedax jedoch nur für den vorhersehbaren, vertragstypischen Schaden. myMedax haftet nicht für die leicht fahrlässige Verletzung anderer als der in den vorstehenden Sätzen genannten Pflichten.</p>
                          </li>
                          <li><p>Die vorstehenden Haftungsbeschränkungen gelten nicht bei Verletzung von Leben, Körper und Gesundheit, für einen Mangel nach Übernahme einer Garantie für die Beschaffenheit des Produktes und bei arglistig verschwiegenen Mängeln. Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.</p>
                          </li>
                          <li><p>Soweit die Haftung von myMedax ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung von Arbeitnehmern, Vertretern und Erfüllungsgehilfen.</p>
                          </li>
                        </ol>
                        <p>  <strong>§ 11</strong></p>
                        <p>  <strong>Links zu anderen Website</strong></p>
                        <ol>
                          <li><p>Für Links, die nicht von der SUXEDO GmbH &amp; Co. KG betrieben werden und sich auf deren Webseite befinden, haben wir keinerlei Möglichkeit, den Inhalt dieser Webseite zu kontrollieren, da diese völlig unabhängig von uns ist.</p>
                          </li>
                          <li><p>Aus diesem Grund übernehmen wir keinerlei Verantwortung für die Inhalte dieser Webseiten und die Folgen ihrer Verwendung durch die Besucher dieser. Das Aufrufen aller über Links erreichbaren Webseiten geschieht auf eigene Gefahr. Es erfolgt kein gesonderter Hinweis, wenn Benutzer die Website verlassen. Wir bitten Sie aber, uns umgehend auf rechtswidrige und zweifelhafte Inhalte der verlinkten Website aufmerksam zu machen.</p>
                          </li>
                          <li><p>Andere Webseiten verfügen möglicherweise über einen Link zu den Webseiten von der SUXEDO GmbH &amp; Co. KG. Ein solcher Link erfordert unsere vorherige Zustimmung.</p>
                          </li>
                        </ol>
                        <p>  <strong>§ 12</strong></p>
                        <p>  <strong>Datenschutz</strong></p>
                        <ol>
                          <li><p>Es kann vorkommen, dass Daten und Informationen der Besucher und Nutzer (Datum, Uhrzeit, aufgerufene Seite) über den Zugriff auf dem Server gespeichert werden. Wir weisen darauf hin, dass - ohne Einwilligung - keine personenbezogenenen (z. B. Name, Anschrift oder E-Mail-Adresse) Daten gespeichert werden.</p>
                          </li>
                          <li><p>Sollten personenbezogene Daten erhoben werden, verpflichten wir uns dazu, das vorherige Einverständnis des Nutzers der Website einzuholen. Wir verpflichten uns dazu, keine Daten an Dritte weiterzugeben, es sei denn der Besucher oder Nutzer willigt vorher ein.</p>
                          </li>
                          <li><p>Wir weisen darauf hin, dass die Übertragung von Daten im Internet (z. B. per E-Mail) Sicherheitslücken aufweisen kann. Demnach kann ein fehlerfreier und störungsfreier Schutz der Daten Dritter nicht vollständig gewährleistet werden. Diesbezüglich ist unsere Haftung ausgeschlossen.</p>
                          </li>
                          <li><p>Dritte sind nicht dazu berechtigt, Kontaktdaten für gewerbliche Aktivitäten zu nutzen.</p>
                          </li>
                          <li><p>Der Nutzer hat das Recht auf Auskunft. Sie haben jederzeit das Recht, von uns über den Sie betreffenden Datenbestand vollständig und unentgeltlich Auskunft zu erhalten.</p>
                          </li>
                          <li><p>Des Weiteren besteht ein Recht auf Berichtigung/Löschung von Daten/ Einschränkung der Verarbeitung für den Nutzer.</p>
                          </li>
                          <li><p>Weitere Angaben werden separat in der Datenschutzrichtlinie gemacht.</p>
                          </li>
                        </ol>
                        <p>  <strong>§ 13</strong></p>
                        <p>  <strong>Cookies</strong></p>
                        <ol>
                          <li><p>Zur Anzeige des Produktangebotes kann es vorkommen, dass wir Cookies einsetzen. Bei Cookies handelt es sich um kleine Textdateien, die lokal im Zwischenspeicher des Internet-Browsers des Seitenbesuchers gespeichert werden.</p>
                          </li>
                          <li><p>Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge, durch welche Internetseiten und Server dem konkreten Internetbrowser zugeordnet werden können, in dem das Cookie gespeichert wurde. Dies ermöglicht es den besuchten Internetseiten und Servern, den individuellen Browser der betroffenen Person von anderen Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann über die eindeutige Cookie-ID wiedererkannt und identifiziert werden.</p>
                          </li>
                          <li><p>Durch den Einsatz von Cookies kann den Nutzern dieser Internetseite nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung nicht möglich wären.</p>
                          </li>
                          <li><p>Wir weisen Sie darauf hin, dass einige dieser Cookies von unserem Server auf Ihr Computersystem überspielt werden, wobei es sich dabei meist um so genannte sitzungsbezogene Cookies handelt. Sitzungsbezogene Cookies zeichnen sich dadurch aus, dass diese automatisch nach Ende der Browser-Sitzung wieder von Ihrer Festplatte gelöscht werden. Andere Cookies verbleiben auf Ihrem Computersystem und ermöglichen es uns, Ihr Computersystem bei Ihrem nächsten Besuch wieder zu erkennen (sog. dauerhafte Cookies).</p>
                          </li>
                          <li><p>Sie können der Speicherung von Cookies widersprechen, hierzu steht Ihnen ein Banner zu Verfügung dem Sie widersprechen/annehmen können.</p>
                          </li>
                          <li><p>Selbstverständlich können Sie Ihren Browser so einstellen, dass keine Cookies auf der Festplatte abgelegt werden bzw. bereits abgelegte Cookies wieder gelöscht werden. Die Anweisungen bezüglich der Verhinderung sowie Löschung von Cookies können Sie der Hilfefunktion Ihres Browsers oder Softwareherstellers entnehmen.</p>
                          </li>
                        </ol>
                        <p>  <strong>§ 14</strong></p>
                        <p>  <strong>Änderungen der Nutzungsbedingugnen</strong></p>
                        <p>  Wir behalten uns das Recht vor, Änderungen an Diensten von myMedax, Regelwerken, Bedingungen einschließlich dieser Nutzungsbedingungen jederzeit vorzunehmen. Sie unterliegen den Allgemeinen Geschäftsbedingungen, Vertragsbedingungen und Nutzungsbedingungen, die zu dem Zeitpunkt in Kraft sind, an dem Sie die Dienste von myMedax nutzen. Falls eine dieser Bedingungen für unwirksam, nichtig oder aus irgendeinem Grund für undurchsetzbar gehalten wird, gilt diese Regelung als abtrennbar und beeinflusst die Gültigkeit und Durchsetzbarkeit aller verbleibenden Regelungen nicht.</p>
                        <p>  <strong>§ 15</strong></p>
                        <p>  <strong>Kein Verzicht</strong></p>
                        <p>  Wenn Sie diese Nutzungsbedingungen verletzen und wir unternehmen hiergegen nichts, sind wir weiterhin berechtigt, von unseren Rechten bei jeder anderen Gelegenheit, in der Sie diese Nutzungsbedingungen verletzen, Gebrauch zu machen.</p>
                        <p>  <strong>§ 16</strong></p>
                        <p>  <strong>Gerichtsstand und anwendbares Recht</strong></p>
                        <ol>
                          <li><p>Für Meinungsverschiedenheiten und Streitigkeiten anlässlich dieses Vertrages gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.</p>
                          </li>
                          <li><p>Alleiniger Gerichtsstand bei Bestellungen von Kaufleuten, juristischen Personen des öffentlichen Rechts oder öffentlich-rechtlichen Sondervermögen ist der Sitz des Anbieters.</p>
                          </li>
                        </ol>
                        <p>  <strong>§ 17</strong></p>
                        <p>  <strong>Schlussbestimmungen</strong></p>
                        <ol>
                          <li><p>Die Vertragssprache ist deutsch.</p>
                          </li>
                          <li><p>Wenn Sie diese allgemeinen Nutzungsbedingungen verletzen und wir unternehmen hiergegen nichts, sind wir weiterhin berechtigt, von unseren Rechten bei jeder anderen Gelegenheit, in der Sie diese Nutzungsbedingungen verletzen, Gebrauch zu machen.</p>
                          </li>
                          <li><p>Wir behalten uns das Recht vor, Änderungen an unserer Website, Regelwerken, Bedingungen einschließlich dieser Nutzungsbedingungen jederzeit vorzunehmen. Falls eine Regelung in diesen Nutzungsbedingungen unwirksam, nichtig oder aus irgendeinem Grund undurchsetzbar ist, gilt diese Regelung als abtrennbar und beeinflusst die Gültigkeit und Durchsetzbarkeit der verbleibenden Regelungen nicht.</p>
                          </li>
                          <li><p>Die Unwirksamkeit einer Bestimmung berührt die Wirksamkeit der anderen Bestimmungen aus dem Vertrag nicht. Sollte dieser Fall eintreten, soll die Bestimmung nach Sinn und Zweck durch eine andere rechtlich zulässige Bestimmung ersetzt werden, die dem Sinn und Zweck der unwirksamen Bestimmung entspricht.
                            1*/1**</p>
                          </li>
                        </ol>
                      </div>
                      <div class="buttons mt-4">
                        <button
                          type="button"
                          class="btn btn-success btn-lg mr-1"
                          (click)="onAcceptTos()"
                        >
                          {{_(KEYS.LOGIN.ACCEPT)}}
                        </button>
                        <button
                          type="button"
                          class="btn btn-secondary btn-lg"
                          (click)="onDeclineTos()"
                        >
                          {{_(KEYS.LOGIN.DECLINE)}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
